import axios from "axios";
import { useState } from "react";

const App = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resp, setresp] = useState({});

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        "https://api.roomnest.me/index.php/api/v1/user/register",
        { first_name: firstname, last_name: lastname, email, password },
        { withCredentials: true }
      );
      console.log(data);
      console.log(resp);

      if (data.token) {
        setresp(data);
        // const now = new Date();
        // const time = now.getTime();
        // const exp = time + 36000 * 1000 * 10;
        // now.setTime(exp);
        // console.log(now.toUTCString());
        // document.cookie =
        //   "token=" +
        //   data.token +
        //   "; expires='" +
        //   now.toUTCString() +
        //   "'; path=/; Secure; domain=roomnest.me; SameSite=None";
        // console.log(document.cookie);
      }
    } catch {
      console.log("error");
    }
  };

  const getData = async () => {
    try {
      const data = await axios.get(
        "https://api.roomnest.me/index.php/api/v1/user/getprofile",
        { withCredentials: true }
      );
      console.log(data);
      document.write(JSON.stringify(data.data));
    } catch {
      console.log("error");
    }
  };

  return (
    <>
      <div>
        <input
          placeholder="first name"
          value={firstname}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <input
          placeholder="last name"
          value={lastname}
          onChange={(e) => setLastName(e.target.value)}
        />

        <input
          placeholder="email "
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <input
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>

      <button onClick={getData}>Get Data</button>
      <h1>{resp.data}</h1>
    </>
  );
};

export default App;
